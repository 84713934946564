import {Link} from 'react-router-dom';
import {useMediaQuery} from 'react-responsive';
import { useState, useEffect } from 'react';

import successCheck from '../images/successCheck.png';
import failCheck from '../images/failCheck.png';
import passChange from '../images/passChange.png';
import icon from '../images/icon.png'

import loadingLight from '../videos/loadingLight.mp4'

import '../css/ResetPassword.css'

import * as firebase from "firebase/app";
import * as firebaseAuth from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyCM7Mt1qNfee0sXAN5No5OtIw-aVKrnh9A",
    authDomain: "mindful-360420.firebaseapp.com",
    projectId: "mindful-360420",
    storageBucket: "mindful-360420.appspot.com",
    messagingSenderId: "352927604037",
    appId: "1:352927604037:web:c9fbbf1dd5f1bee723b22c",
    measurementId: "G-QX1H6635E6"
};


const app = firebase.initializeApp(firebaseConfig);
const auth = firebaseAuth.getAuth(app);

export default function ResetPassword(){

    const [newPass, setNewPass] = useState('');
    const [confirmPass, setConfirmPass] = useState('');
    const [errorCode, setErrorCode] = useState('');
    const [oobCode, setOobCode] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [passSuccess, setPassSuccess] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);

    const isMobile = useMediaQuery({
        query: '(max-width: 700px)'
    });

    //https://mindfulapp.info/resetPassword?mode=resetPassword&oobCode=07NozAXdshvVEC071CVOUMvunOdNCTPIwr9sZtq0TfoAAAGLC2dMsg&apiKey=AIzaSyCM7Mt1qNfee0sXAN5No5OtIw-aVKrnh9A&lang=en

    useEffect(()=>{
        const inputs = document.querySelectorAll("input");

        inputs.forEach((input)=>{
            input.addEventListener('focus', ()=>{
                if(input.style.border.includes("red")){
                    const inputs = document.querySelectorAll("input");
                    setErrorCode(" ");
                    inputs.forEach((inputStyle)=>{
                        inputStyle.style = "1px solid #C6C6C6;";
                    });
                }
            });
        });

        const urlParams = (new URLSearchParams(window.location.search));

        const code = urlParams.get('oobCode');

        if(code != null){
            setOobCode(code);
        }

    }, []);

    const handleSubmit = (e) =>{

        setShowModal(true);

        e.preventDefault();

        //console.log(newPass);
        //console.log(confirmPass);

        if(newPass === confirmPass){
            console.log('are the same');
            if(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[\w\d/!&*#%]{8,}([^\w\d][\w\d]*)?$/.test(newPass)){

                firebaseAuth.confirmPasswordReset(auth, oobCode, newPass).then(()=>{
                    setIsLoaded(true);
                    setPassSuccess(true);
                    setTimeout(()=>{
                        document.location.href = "https://mindfulapp.info/";
                    }, 3000);
                })
                .catch((error)=>{
                    setIsLoaded(true);
                    setTimeout(()=>{
                        document.location.href = "https://mindfulapp.info/";
                    }, 5000);
                    console.log('an error occured');
                    console.log(error.message);
                })

            }
            else{
                const form = document.querySelector("form");

                const inputs = form.getElementsByClassName("inputs");

                inputs.confirmPass.value = "";
                inputs.newPass.value = "";

                inputs.newPass.style="border: 1px solid red;"
                inputs.confirmPass.style="border: 1px solid red;"

                setErrorCode("Password must contain at least 1 uppercase letter, 1 lowercase letter, and 1 number.");
            }
        }
        else{
            const form = document.querySelector("form");

            const inputs = form.getElementsByClassName("inputs");

            inputs.confirmPass.value = "";
            inputs.newPass.value = "";

            inputs.newPass.style="border: 1px solid red;"
            inputs.confirmPass.style="border: 1px solid red;"

            setErrorCode("Passwords do not match!");
        }
    }

    return(
        <div id="body" style={{height: isMobile ? '90vh' : undefined}}>
            <div id="fake-nav-container" style={{minHeight: isMobile ? '8%' : undefined}}>
                <div>
                    <Link to={"/"} id="home-link-container">
                        <img src={icon} style={{width: isMobile ? '8%' : undefined}} id="icon-img" alt="Mindful logo"/>
                        <p id="home-link-container-p" style={{fontSize: isMobile ? 20 : undefined}}>Mindful</p>
                    </Link>
                </div>
            </div>
            <div id="form-container" style={{width: isMobile ? '95%' : undefined, maxHeight: isMobile ? '35%' : undefined}}>
                <div id="form-header">
                    <img id="copDino-img" src={passChange} alt="The Mindful dino wearing sunglasses and a cop hat."/>
                    <h1 style={{fontSize: isMobile ? 22 : undefined}}>Mindful Password Reset</h1>
                </div>
                <form onSubmit={handleSubmit}>
                    <div style={isMobile ? {flex: 1, display: 'flex', flexDirection: 'column', width: '130%'} : {}}>
                        <label for="newPass" id="newPassLabel">New Password:</label>
                        <input class="inputs" id="newPass" type={"password"} onChange={(e)=>{setNewPass(e.target.value);}}/>
                        <label for="confirmPass" id="confirmPassLabel" style={{marginLeft: isMobile ? 0 : undefined, marginTop: isMobile ? 15 : undefined}}>Confirm Password:</label>
                        <input class="inputs" id="confirmPass" type={"password"} onChange={(e)=>{setConfirmPass(e.target.value);}}/>
                    </div>
                    <p id="errorCodeText">{errorCode}</p>
                    <button type="submit" id="passSubmitButton"><p>Reset Password</p></button>
                </form>
            </div>
            {showModal && 
                <div style={{display: 'flex',  width: '100vw', height: '100vh', flex: 1, position: 'absolute', justifyContent: 'center', alignContent: 'center', alignItems: 'center'}}>
                    <div id="modal-backdrop">
                    </div>
                    <div id={isMobile ? "confirmation-modal-mobile" : "confirmation-modal"} style={{height: isMobile && passSuccess ? '25%' : undefined}}>
                        {!isLoaded && <video src={loadingLight} autoPlay={true} muted loop playsInline style={{width: '30%', aspectRatio: 1}}/>}
                        {passSuccess && isLoaded && <img src={successCheck} id="modal-img" style={{marginBottom: isMobile ? -20 : undefined, marginTop: isMobile ? passSuccess ? -10 : -40 : undefined}} alt="Checkmark"/>}
                        {!passSuccess && isLoaded && <img src={failCheck} id="modal-img" style={{marginBottom: isMobile ? -20 : undefined, marginTop: isMobile ? passSuccess ? -10 : -40 : undefined}} alt="X signifying an error"/>}
                        <p>{!isLoaded ? 'One moment...' : passSuccess ? 'Password was successfully changed!' : 'Something went wrong!'}</p>
                        {isLoaded && !passSuccess && <p style={{fontSize: isMobile ? 14 :  18, position: 'absolute', bottom: '8%', textAlign: 'center', width: '80%'}}>Go back to the app and request another password reset.</p>}
                    </div>
                </div>
            }
        </div>
    );
}