import {Link, useRouteError} from 'react-router-dom';
import {useMediaQuery} from 'react-responsive';

import errorDino from '../images/errorDino.png';
import icon from '../images/icon.png'

import '../css/ErrorPage.css'

export default function ErrorPage(){
    const error = useRouteError();
    console.error(error);

    const isMobile = useMediaQuery({
        query: '(max-width: 700px)'
    });

    return(
        <div id="body" style={{height: isMobile ? '90vh' : undefined}}>
            <div id="fake-nav-container" style={{minHeight: isMobile ? '8%' : undefined}}>
                <div>
                    <Link to={"/"} id="home-link-container">
                        <img src={icon} style={{width: isMobile ? '8%' : undefined}} id="icon-img" alt="Mindful logo"/>
                        <p id="home-link-container-p" style={{fontSize: isMobile ? 20 : undefined}}>Mindful</p>
                    </Link>
                </div>
            </div>
            <div id="error-page" style={{width: isMobile ? '85%' : undefined, maxHeight: isMobile ? '35%' : undefined}}>
                <div id="header-container" style={{maxHeight: isMobile ? '35%' : undefined}}>
                    <h1 style={{fontSize: isMobile ? 55 : undefined}}>Oh no!</h1>
                    <img style={{width: isMobile ? '25%' : undefined}} id="errorDino-img" src={errorDino} alt="Dinosaur with caution symbols above its head"/>
                </div>
                <p style={{fontSize: isMobile ? 18 : undefined, textAlign: 'center'}}>Sorry, an unexpected error has occurred.</p>
                <p style={{fontSize: isMobile ? 16 : undefined, textAlign: 'center'}}>
                    <i>{error.status} </i>
                    <i>{error.statusText || error.message}</i>
                </p>
            </div>
        </div>
    );
}